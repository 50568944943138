import React, { useEffect } from 'react';
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import Nav from '../Nav/Nav';
import NavBar from '../NavBar/NavBar';
import BottomNav from '../BottomNav/BottomNav';
import Footer from '../Footer/Footer';

import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';

import AboutPage from '../AboutPage/AboutPage';
import UserPage from '../UserPage/UserPage';
import InfoPage from '../InfoPage/InfoPage';
import LandingPage from '../LandingPage/LandingPage';
import LoginPage from '../LoginPage/LoginPage';
import RegisterPage from '../RegisterPage/RegisterPage';

import CreateAccountPage from '../CreateAccountPage/CreateAccountPage';
import ProfilePage from '../ProfilePage/ProfilePage';
import MyCampaignsPage from '../MyCampaignsPage/MyCampaignsPage';
import DiscoverPage from '../DiscoverPage/DiscoverPage';
import PledgesPage from '../PledgesPage/PledgesPage';
import CampaignDetailsPage from '../CampaignDetailsPage/CampaignDetailsPage';
import CreatePledge from '../CreatePledge/CreatePledge';
import PledgeCard from '../PledgeCard/PledgeCard';
import PledgeDetailsPage from '../PledgeDetailsPage/PledgeDetailsPage';
import CreateCampaign from '../CreateCampaignPage/CreateCampaign';
import CreateItem from '../CreateItem/CreateItem';



import logo from '../../media/pigeon_logo.svg';

import Sandbox from '../Sandbox/Sandbox';

import './App.css';
import { Campaign } from '@mui/icons-material';

function App() {
  const dispatch = useDispatch();

  const user = useSelector(store => store.user);

  useEffect(() => {
    dispatch({ type: 'FETCH_USER' });
  }, [dispatch]);

  return (
    <Router>

      
      {/* SAND BOX */}
        {/* <Route>
            <Sandbox />
        </Route> */}


      <div>
      {/* <NavBar /> */}

        {/* LOGO */}
          <center>
              <Nav/>
          </center>



        <Switch>
          {/* Visiting localhost:3000 will redirect to localhost:3000/home */}
          <Redirect exact from="/" to="/home" />

          {/* Visiting localhost:3000/about will show the about page. */}
          <Route
            // shows AboutPage at all times (logged in or not)
            exact
            path="/about"
          >
            <AboutPage />
          </Route>

          {/* For protected routes, the view could show one of several things on the same route.
            Visiting localhost:3000/user will show the UserPage if the user is logged in.
            If the user is not logged in, the ProtectedRoute will show the LoginPage (component).
            Even though it seems like they are different pages, the user is always on localhost:3000/user */}
          <ProtectedRoute
            // logged in shows UserPage else shows LoginPage
            exact
            path="/user"
          >
            <UserPage />
          </ProtectedRoute>

          <ProtectedRoute
            // logged in shows InfoPage else shows LoginPage
            exact
            path="/info"
          >
            <InfoPage />
          </ProtectedRoute>

          <Route
            exact
            path="/login"
          >
            {user.id ?
              // If the user is already logged in, 
              // redirect to the /user page
              <Redirect to="/user" />
              :
              // Otherwise, show the login page
              <LoginPage />
            }
          </Route>

          <Route
            exact
            path="/registration"
          >
            {user.id ?
              // If the user is already logged in, 
              // redirect them to the /user page
              <Redirect to="/user" />
              :
              // Otherwise, show the registration page
              <RegisterPage />
            }
          </Route>

          <Route
            exact
            path="/registration"
          >
            {user.id ?
              // If the user is already logged in, 
              // redirect them to the /user page
              <Redirect to="/user" />
              :
              // Otherwise, show the registration page
              <RegisterPage />
            }
          </Route>

          






          {/* CREATE ACOUNT PAGE ROUTE */}
            <Route exact path="/createAccountPage">
              <CreateAccountPage/>
            </Route>


          {/* PROFILE PAGE ROUTE */}
            <ProtectedRoute
              // logged in shows UserPage else shows LoginPage
              exact
              path="/profile"
            >
              <ProfilePage />
            </ProtectedRoute>

          {/* MY CAMPAIGNS PAGE ROUTE */}
            <ProtectedRoute
              // logged in shows UserPage else shows LoginPage
              exact
              path="/my/campaigns"
            >
              <MyCampaignsPage />
            </ProtectedRoute>

          {/* MY CAMPAIGNS PAGE ROUTE */}
            <ProtectedRoute
              // logged in shows UserPage else shows LoginPage
              exact
              path="/pledges/"
            >
              <PledgesPage />
            </ProtectedRoute>

            {/* DISCOVER PAGE ROUTE */}
            <Route
              // logged in shows UserPage else shows LoginPage
              exact
              path="/discover"
            >
              <DiscoverPage />
            </Route>

            {/* DISCOVER PAGE ROUTE */}
            <Route
              // logged in shows UserPage else shows LoginPage
              exact
              path="/campaign/details/:id"
            >
              <CampaignDetailsPage />
            </Route>


            {/* CREATE PLEDGE ROUTE */}
            <Route
              // logged in shows UserPage else shows LoginPage
              exact
              path="/create/pledge/:id"
            >
              <CreatePledge />
            </Route>


            {/* CREATE PLEDGE ROUTE */}
            <Route
              // logged in shows UserPage else shows LoginPage
              exact
              path="/pledge/details/:id"
            >
              <PledgeDetailsPage />
            </Route>

              {/* CREATE PLEDGE ROUTE */}
              <Route
              // logged in shows UserPage else shows LoginPage
              exact
              path="/create/campaign/:createCampaignId"
            >
              <CreateCampaign />
            </Route>

            {/* CREATE PLEDGE ROUTE */}
            <Route
              // logged in shows UserPage else shows LoginPage
              exact
              path="/create/campaign/items/:createCampaignId"
            >
              <CreateItem />
            </Route>










          <Route
            exact
            path="/home"
          >
            {user.id ?
              // If the user is already logged in, 
              // redirect them to the /user page
              <Redirect to="/user" />
              :
              // Otherwise, show the Landing page
              <LandingPage />
            }
          </Route>

          {/* If none of the other routes matched, we will show a 404. */}
          <Route>
            <h1>404</h1>
          </Route>
          
        </Switch>
        {/* <Footer /> */}
      </div>

      {/* <BottomNav/>         */}


      <NavBar />
    </Router>
  );
}

export default App;
